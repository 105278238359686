import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildLoadRankedColumns } from '../../../network/urls';
import { sendRetrieveRequest, convertToCamel } from '../../../utils';
import { CandidateColumn, RankedColumn, RankedColumnResponse } from '../types';

export const loadRankedColumns = (
    activity: string,
    actionName: string,
    processResultFunction: Function,
    type: ACTIVITY_TYPES,
): Observable<{
    result: RankedColumnResponse;
    actionName: string;
    processResultFunction: Function;
    type: ACTIVITY_TYPES;
}> => {
    const url = buildLoadRankedColumns(`activities/${activity}`);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedCandidateColumns = convertToCamel<Record<string, CandidateColumn>>(
                response.candidate_columns_map,
            );
            const convertedRankedColumns = convertToCamel<Record<string, RankedColumn>>(
                response.ranked_columns_map,
            );
            return observableOf({
                result: {
                    candidateColumnsMap: convertedCandidateColumns,
                    rankedColumnsMap: convertedRankedColumns,
                } as RankedColumnResponse,
                actionName,
                processResultFunction,
                type,
            });
        }),
    );
};
